/**
  * .wm-toggle 1.0.0
  * Inhalt ein- und ausklappen
  * Im Handbuch: /pattern-library/patterns/toggle/
*/

.wm-toggle {
  &__container {
    border-bottom: 0;
    padding-right: $wm-spacing-small;
    padding-bottom: 1rem;
    padding-left: $wm-spacing-small;

    .wm-no-js &,
    .wm-js:not(.wm-jsm) & {
      padding-top: 0;
      padding-right: 0;
      padding-left: 0;
    }

    @include inside("[data-mobile-only]") {
      min-height: auto;
      border: none;
      margin-bottom: $wm-spacing-small;
      padding: 0;
      background: none;
      font-size: $wm-f-h3-size-large;

      @include mq(l) {
        font-size: $wm-f-h3-size-large;
      }
    }
  }

  &__panel-inner {
    @include wm-block;

    border-bottom: none;

    @include inside(".wm-jsm [data-mobile-only]") {
      border: 0;
      padding: 0;
      background: none;
    }
  }

  &__icon {
    @include inside("[data-mobile-only]") {
      display: none;
    }
  }
}
