/**
  * functions 1.0.0
  * Sass Funktionen
*/

/**
  * Returns UI colors
  * @param { string } unqiue color key
*/
@function wm-ui-color($key) {
  @if map-has-key($wm-ui-colors, $key) {
    // @debug map-get($wm-ui-colors, $key);

    @return nth(map-get($wm-ui-colors, $key), 2);
  }

  @warn "Unknown `#{$key}` in $wm-ui-colors.";

  @return null;
}

/**
  * Returns Brand colors
  * @param { string } unqiue color key
*/
@function wm-brand-color($key) {
  @if map-has-key($wm-brand-colors, $key) {
    // @debug map-get($wm-brand-colors, $key);

    @return nth(map-get($wm-brand-colors, $key), 2);
  }

  @warn "Unknown `#{$key}` in $wm-brand-colors.";

  @return null;
}

/**
  * Returns url function for SVG backgrounds
  * @param { string } icon file name
*/
@function wm-icon-bg($icon) {
  @return url('../icons/css/#{$icon}.svg');
}

/**
  * Returns Map colors
  * @param { string } unqiue color key
*/
@function wm-map-color($key) {
  @if map-has-key($wm-map-colors, $key) {
    // @debug map-get($wm-map-colors, $key);

    @return nth(map-get($wm-map-colors, $key), 2);
  }

  @warn "Unknown `#{$key}` in $wm-map-colors.";

  @return null;
}
