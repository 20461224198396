/**
  * .wm-quicklinks 1.0.0
  * Linkliste mit Pfeilen
  * Im Handbuch: /pattern-library/patterns/quicklinks/
*/

.wm-quicklinks {
  &__icon {
    width: 3.4rem;
    height: 3.4rem;
  }

  &__items {
    &--2col,
    &--2col-limited {
      column-count: 2;
      column-gap: $wm-grid-gap * 3;
    }

    &--3col {
      column-count: 2;
      column-gap: $wm-grid-gap * 3;
    }

    .wm-jsm & {
      &--md {
        display: block;
      }
    }
  }

  &__item {
    @include inside(".wm-quicklinks__items--2col-limited") {
      display: block;
    }
  }

  &__dropdown {
    .wm-jsm & {
      display: none;
    }
  }
}
