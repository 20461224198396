

.wm-tabs {
    &__panel {
        .wm-jsm & {
            padding:
                ($wm-spacing-large - $wm-spacing-xxsmall)
                $wm-spacing-large
                $wm-spacing-large;
        }
    }
}