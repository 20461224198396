/**
  * button-mixins 1.0.0
  * Sass Mixins speziell für Buttons
*/

@mixin wm-button-sceleton {
  @include wm-font-weight("bold");

  border: 1px solid $wm-focus-color;
  padding: 0.463em 0.88em;
  background-color: $wm-focus-color;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: inherit;
  font-size: $wm-f-size-base;
  line-height: 1.45;
  text-align: center;
  text-transform: uppercase;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &::-moz-focus-inner {
    border: none;
  }

  &[aria-disabled],
    &[disabled] {
    opacity: 0.7;
    cursor: not-allowed;
    pointer-events: none;
  }

  &[aria-disabled]:focus-visible {
    outline-color: wm-brand-color('wien');
  }
  &[download] {
    padding-right: $wm-spacing-medium;
    padding-right: 2.5em;
    background-position: right 0.5em center;
    background-size: auto 75%;

    &:not(:hover) {
        background-image: wm-icon-bg("download-light");
    }
  }

  & > *:not(.wm-h-vh) + * {
    margin-left: $wm-spacing-xsmall;
  }

  .js-focus-visible &.focus-visible {
    outline: $wm-focus-outline-alt;
  }

  @include state(":focus") {
    outline: $wm-focus-outline-alt;
  }
}

@mixin wm-button-color($bg, $text, $hover-bg, $hover-text) {
  &[disabled] {
    border-color: transparent;
    background-color: rgba($bg, 0.2);
  }

  @include state(":linkvisited") {
    border-color: $bg;
    background: $bg;
    color: $text;
  }

  @include state(":hover") {
    background-color: $hover-bg;
    color: $hover-text;

    &[disabled] {
      border-color: transparent;
      background-color: rgba($bg, 0.2);
      color: $text;
      cursor: not-allowed;
    }
  }
}

@mixin wm-button-color-invert($bg, $hover-bg, $text, $hover-text) {
  &[disabled] {
    border-color: rgba($bg, 0.2);
  }

  @include state(":linkvisited") {
    border-color: $hover-bg;
    background-color: $bg;
    color: $text;
  }

  @include state(":hover") {
    background-color: $hover-bg;
    color: $hover-text;

    &[disabled] {
      color: $text;
      cursor: not-allowed;
    }
  }
}

@mixin wm-button($color, $text-color, $hover-color, $hover-text) {
  @include wm-button-color($color, $text-color, $hover-color, $hover-text);

  &-i {
    @include wm-button-color-invert(
      $hover-color,
      $color,
      $hover-text,
      $text-color
    );
  }
}
