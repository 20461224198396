/**
  * .wm-nav-tag 1.0.0
  * Tag Navigation am Anfang einer Seite
  * Im Handbuch: /pattern-library/patterns/tags/
*/

.wm-nav-tag {
  &__link {
    min-height: 4rem;
    padding: 0.45rem 1.5rem 0.46rem;
    font-size: $wm-f-size-base;
  }
}
