/**
  * .wm-breaking-news-wrapper und wm-breaking-news 1.0.0
  * Inhalt ein- und ausklappen
  * Im Handbuch: /pattern-library/patterns/breaking-news/
*/

.wm-breaking-news-wrapper {
  z-index: $wm-z-banner-desktop;

  .wm-jsm & {
    &--static {
      transform: none;
    }

    @include mq(l) {
      transform: translateY($wm-site-header-height-large);
    }
  }

  @include inside(".wm-jsm .wm-site-content--in-page-nav") {
    top: 0;
  }

  @include wm-empty-container {
    &--static {
      @include inside(".wm-jsm .wm-site-content--in-page-nav") {
        top: ($wm-site-header-height-large + $wm-in-page-nav-height) - $wm-site-content-padding-top-in-page;
      }
    }
  }
}

.wm-breaking-news {
  font-size: $wm-f-size-large;

  &__heading {
    padding: 0;
    display: inline-block;
    font-size: $wm-f-size-large;
  }

  &__inner {
    padding-right: 3.5rem;

    &--static {
      padding: 0;
    }
  }
}
