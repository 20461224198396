/**
  * .wm-nav-wrapper 1.0.0
  * Navigation von Microsites und von wien.gv.at
  * Im Handbuch: /pattern-library/patterns/menue/
*/

.wm-nav-main {
  &__header {
    .wien [data-nav-unlock="true"] & {
      right: 2rem;
    }
  }

  &__content {
    padding-right: 2rem;
  }

  &__actions {
    flex-grow: initial;
  }
}
