/**
  * vars 1.0.0
  * Sass Variablen
*/


// Custom properties

:root {
  --nav_background: #{wm-brand-color("weiss")};
  --nav_background1: #e6e3df;
  --nav_background2: #{wm-brand-color("nebelgrau-light")};
  --nav_link: #{wm-brand-color("fastschwarz")};
  --nav_link_active: #{wm-brand-color("fastschwarz")};
  --nav_link_child: #{wm-brand-color("fastschwarz")};
  --nav_background_full: #{wm-brand-color("nebelgrau")};
  --wm-color-weiss: #{wm-brand-color("weiss")};
  --wm-color-fastschwarz: #{wm-brand-color("fastschwarz")};
}

/* ##### Fonts ##### */

$wm-f-stack: "WienerMelange", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", sans-serif;
$wm-f-stack-fallback: "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", sans-serif;

$wm-f-size-small: 1.6rem;
$wm-f-size-base: 1.7rem;
$wm-f-size-medium: 1.8rem;
$wm-f-size-large: 2rem;
$wm-f-size-xlarge: 2.5rem;
$wm-f-regular: 400;
$wm-f-bold: 600;
$wm-f-bold-var: 600;
$wm-f-extrabold: 800;
$wm-f-extrabold-var: 740;

// Headings
$wm-f-h1-size: 3rem;
$wm-f-h1-size-large: 3.5rem;
$wm-f-h2-size: 2.5rem;
$wm-f-h2-size-large: 3rem;
$wm-f-h3-size: 2.1rem;
$wm-f-h3-size-large: 2.2rem;
$wm-f-h4-size: $wm-f-size-base;
$wm-f-h4-size-large: $wm-f-size-base;
$wm-f-h5-size: $wm-f-size-base;
$wm-f-h6-size: $wm-f-size-base;

/* ##### Design ##### */

$wm-shadow-desktop: 0 4px 8px rgba(wm-brand-color("fastschwarz"), 0.16);
$wm-shadow-desktop-dark: 0 4px 8px rgba(wm-brand-color("weiss"), 0.16);
$wm-focus-color: wm-ui-color('interaktiv');
$wm-focus-color-light: wm-brand-color('flieder-light');
$wm-focus-outline: 5px solid $wm-focus-color;
$wm-focus-outline-alt: 5px solid wm-brand-color('fastschwarz');
$wm-focus-outline--small: 2px solid $wm-focus-color;
$wm-focus-outline--c-light: 5px solid $wm-focus-color-light;
$wm-focus-outline--c-light--small: 2px solid $wm-focus-color-light;

/* ##### Sizes ##### */

// In page nav
$wm-in-page-nav-height: 5.6rem;
$wm-in-page-nav-height-large: 55rem;

// Main Nav
$wm-nav-max-width-large: 41.5rem;
$wm-nav-main-ms-height: 5.6rem;
$nav_fallback_background: wm-brand-color('flieder');
$nav_fallback_background-light: wm-brand-color('flieder-light');

// General
$wm-site-max-width: 52rem;
$wm-site-max-width-medium: 78.6rem;
$wm-site-max-width-number: 120;
$wm-site-max-width-large: $wm-site-max-width-number * 1rem;
$wm-site-header-height: 6rem;
$wm-site-header-height-large: 10rem;
$wm-site-header-height-scrolled: $wm-site-header-height;
$wm-site-header-ms-height: $wm-site-header-height + $wm-nav-main-ms-height;
$wm-site-header-ms-height-large: $wm-site-header-height-large + $wm-nav-main-ms-height;
$wm-spacing-xxsmall: 0.5rem;
$wm-spacing-xsmall: 1rem;
$wm-spacing-small: 1.5rem;
$wm-spacing-medium: 2rem;
$wm-spacing-large: 3rem;
$wm-spacing-xlarge: 5rem;
$wm-spacing-xxlarge: 8rem;
$wm-site-list-padding: 2rem;
$wm-site-padding: $wm-spacing-small;
$wm-site-content-padding-top: $wm-site-header-height + $wm-spacing-small;
$wm-site-content-padding-top-large: $wm-site-header-height-large + $wm-spacing-small;
$wm-site-content-padding-top-in-page: $wm-site-content-padding-top + $wm-in-page-nav-height;
$wm-site-content-padding-top-in-page-large: $wm-site-content-padding-top-large + $wm-in-page-nav-height;
$wm-stage-padding: minmax($wm-site-padding, 1fr);
$wm-stage-height-home: 36rem;
$wm-stage-height-home-large: 50rem;
$wm-form-item-height: 4rem;

// Scroll-Margin top:
// total = 13.6rem
$wm-scroll-margin-top: $wm-in-page-nav-height + $wm-site-header-height + $wm-spacing-medium;

// Tags
$wm-nav-tag-item-margin: 1rem;
$wm-nav-tag-item-radius: 20px;
$wm-nav-tag-item-padding-x: 1.5rem;
$wm-nav-tag-item-padding: 0.9rem 1.5rem 0.8rem;

// Button Tag radius:
$wm-button-tag-radius: 30px;

// Cards
$wm-card-padding-top:  $wm-spacing-small;
$wm-card-padding-sides: $wm-spacing-small;
$wm-card-padding-bottom: ($wm-spacing-small + $wm-spacing-xxsmall);

$wm-card-padding: $wm-card-padding-top $wm-card-padding-sides $wm-card-padding-bottom;

$wm-card-padding-large-top: ($wm-spacing-large - $wm-spacing-xxsmall);
$wm-card-padding-large-sides: $wm-spacing-large;
$wm-card-padding-large-bottom: $wm-spacing-medium;

$wm-card-padding-large: $wm-card-padding-large-top $wm-card-padding-large-sides $wm-card-padding-large-bottom;

$wm-card-shadow: 0 4px 8px 0 rgba(wm-brand-color('fastschwarz'), 0.16);
$wm-card-shadow-large:  0 0 10px 0 rgba(wm-brand-color('fastschwarz'), 0.1);

$wm-card-size-large: 53rem;
$wm-card-size: 35rem;
$wm-card-size-small: 26rem;
// smaller than 35rem in order to fill container earlier with 3 cards
$wm-card-minsize-medium: 32rem;
// (768px - (section 2* padding -1.5rem) - 1rem margin card) / 2
$wm-card-minsize: 36.4rem;

// Tabs
$wm-tabs-color: wm-brand-color("nebelgrau-light");
$wm-tabs-color-contrast: wm-brand-color("weiss");

// Carousel
$wm-carousel-card-padding: 8px;
$wm-carousel-card: 27.6rem;
$wm-carousel-card-small: 32rem;
$wm-carousel-card-medium: 34.5rem;
$wm-carousel-card-large: 36.6rem;
$wm-carousel-card-xlarge: 45rem;
$wm-carousel-card-xxlarge: 55rem;



/* ##### Grid ##### */

$wm-grid-column: 8rem;
$wm-grid-gap: 1rem;

/* ##### Widths ##### */

$wm-page-max-width-s: 56rem;
$wm-page-max-width: 107rem;
$wm-content-max-width: 71rem;
$wm-content-max-width-s: 35rem;

/* ##### Breakpoints ##### */

$mq-breakpoints: (
  xxs: 375px,
  xs: 415px,
  s: 560px,
  m: 768px,
  l: 845px,
  xl: 1024px,
  xl-up: 1025px,
  content-full: 1115px,
  content-full-slider: 1145px,
  wide: $wm-site-max-width-number * 10px
);

/* ##### z-Indices ##### */

$wm-z-header: 1000;
$wm-z-nav-inpage: $wm-z-header - 2;
$wm-z-site-search: $wm-z-header + 1;
$wm-z-site-nav: $wm-z-site-search + 1;
$wm-z-banner: $wm-z-nav-inpage - 1;
$wm-z-banner-desktop: $wm-z-header - 1;
$wm-z-skip-links: 2000;
$wm-z-modal: 2147483648; // Larger than WienBot z-index
$wm-z-dropdown-button: 3;
$wm-z-dropdown-background: 2;

/* ##### Animation ##### */

$wm-anim-short: 0.1s ease-in-out;
$wm-anim-header: 0.3s;
$wm-anim-icons: 0.3s;

/* Wienbot/Chatbot */
$wienbot-height: 7rem;
$wienbot-margin: 1rem;
$wienbot-footer-margin-s: $wienbot-height + $wienbot-margin;
$wienbot-footer-margin-m: $wienbot-height - 2rem;
$wienbot-footer-margin-l: 0;
