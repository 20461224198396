/**
  * .wm-card 1.0.0
  * Darstellung von Inhalten in Cards
  * Im Handbuch: /pattern-library/patterns/cards/
*/

/*.wm-card {
  box-shadow: $wm-card-shadow-large;

  &__content {
    //margin-bottom: $wm-spacing-xlarge;

    @include inside(".wm-section__content--carousel") {
      &--no-margin {
        margin-bottom: 0;
      }
    }
  }

  &--raw {
    box-shadow: none;
  }

  &__video {
    .wm-btn--round {
      width: 7rem;
      height: 7rem;

      [data-size="medium"] & {
        width: 6rem;
        height: 6rem;
      }

      [data-size="small"] & {
        width: 5rem;
        height: 5rem;
      }
    }
  }
}
*/