/**
  * .wm-accordion 1.0.0
  * Akkordeons
  * Im Handbuch: /pattern-library/patterns/akkordeon/
*/

.wm-accordion {
  &[data-mobile-only] {
    @include wm-block;
  }

  &__header {
    border-bottom: 0;
    padding-right: $wm-spacing-small;
    padding-bottom: 1rem;
    padding-left: $wm-spacing-small;
    background-color: wm-brand-color("nebelgrau-light");

    .wm-jsm & {
      padding-right: 0;
      padding-left: 0;
    }

    [data-mobile-only] & {
      min-height: auto;
      border: none;
      padding: 0;
      background: none;
      font-size: $wm-f-h3-size-large;

      @include mq(l) {
        font-size: $wm-f-h3-size-large;
      }
    }
  }

  &__panel-inner {
    border-bottom: none;
    margin-bottom: 1rem;
    padding: 0.3rem $wm-spacing-small 0;
    background-color: wm-brand-color("nebelgrau-light");

    &,
    &:last-child {
      padding-bottom: $wm-spacing-small;
    }

    .wm-jsm [data-mobile-only] & {
      border: 0;
      padding: 0;
      background: none;
    }
  }

  &__button {
    padding: 0.6rem $wm-spacing-small $wm-spacing-xxsmall;
  }

  &__icon {
    [data-mobile-only] & {
      display: none;
    }
  }
}
