/**
  * .wm-carousel 1.0.0
  * Karrusell
  * Im Handbuch: /pattern-library/patterns/karrusell/
*/

.tns-item {
  @include inside('[data-tablet-only="true"]') {
    margin-bottom: 0;
  }
}

.tns-ovh {
  @include inside('.wm-section__content--tablet-only') {
    overflow: hidden;
  }
}

.wm-carousel {
  & > * {
    @include mq(l) {
      flex-basis: $wm-carousel-card-medium;
    }
  }

  &[data-tablet-only] {
    @include mq(m) {
      flex-basis: $wm-carousel-card-medium;
    }
  }
}
