a/**
  * .wm-stage 1.0.0
  * „Schaufenster” am Anfang der Seite
  * Im Handbuch: /pattern-library/patterns/stage/
*/

.wm-stage {
  margin-top: 4rem;
  margin-bottom: 2.3rem;

  // Remove margin when inside Carousel
  .tns-item & {
    margin-bottom: 0;
  }

  &--media {
      margin-top: 0;
  }

  &--color {
      margin-top: 0;

      @supports not (aspect-ratio: 1200 / 450) {
        height: 45rem;
      }
  }

  &--home {
    height: $wm-stage-height-home-large;
    margin-bottom: $wm-spacing-xlarge;
  }

  &__heading {
    font-size: $wm-f-h1-size;
  }

  &__text--color,
  &__text--media {
      padding: $wm-spacing-small;

      // Read padding when inside Carousel
      .tns-item & {
        padding-bottom: $wm-spacing-small;
      }
  }

  &__media {
    & > * {
        &:first-child {
            grid-row: 1 / -1;
            grid-column: 1 / 3;

            @supports not (aspect-ration: 1200 / 450) {
                height: 45rem;
            }

            &::after {
                display: none;
            }
            &:nth-last-child(2){
                grid-column: 1 / -1;
                &::after {
                    display: inline-flex;
                    right: 2px;
                    left: auto;
                }
            }
        }
        &:nth-of-type(2){
            @supports not (aspect-ratio: 1200 / 450) {
                height: 22.25rem;
            }
        }
        &:nth-of-type(3){
            @supports not(aspect-ratio: 1200 / 450){
                height: 22.25rem;
            }
            &::after {
                display: inline-flex;
            }
        }
        &:only-of-type{
            grid-column: 1 / -1;
        }
    }

    &--home {
      height: $wm-stage-height-home-large;

      img {
        height: 100%;
      }
    }
  }

  &:not(&--playing) &__controls {
    margin: auto;
    display: flex;
    justify-content: center;
    top: 50%;
    left: 1.5rem;
    transform: translateY(-50%);
  }

  &:not(&--playing) &__button {
    width: 7rem;
    height: 7rem;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
