/**
  * progress-mixins 1.0.0
  * Sass Mixins speziell für Fortschrittsanzeigen
*/

@mixin wm-progress-left-center {
  right: auto;
  left: 1.5rem;
  transform: translateX(-50%) translateY(-100%);
}

@mixin wm-progress-right-center {
  right: 1.5rem;
  transform: translateX(50%) translateY(-100%);
}
