/**
  * .wm-block 1.0.0
  * Blöcke, die Abstände zwischen Elementen steuern
*/

.wm-block {
  & > * + * {
    margin-top: $wm-spacing-medium;
  }

  & > h3 + * {
    margin-top: $wm-spacing-xsmall;
  }

  & > h4 + * {
    margin-top: 0;
  }

  & > * + h2 {
    margin-top: $wm-spacing-xlarge;
  }

  & > * + h4 {
    margin-top: $wm-spacing-medium;
  }
}
