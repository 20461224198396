/**
  * .wm-section 1.0.0
  * Grobe Inhaltsblöcke auf Seiten
*/

.wm-section {
  &--highlight {
    padding: 2.5rem 0;

    & + & {
      padding-top: 0;
    }
  }

  &--fixed {
    margin-bottom: 0;
  }

  &--intro {
    margin-bottom: $wm-spacing-xxsmall;
  }

  &--large {
    margin-bottom: $wm-spacing-xxlarge;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
