/**
  * .wm-site-search 1.0.0
  * Suche im Header
  * Im Handbuch: /pattern-library/patterns/suchfeld/
*/

.wm-site-search {
  &:not(&--inline) {
    width: 100%;
  }

  &--active {
    padding: 0;
  }

  &--active:not(&--inline) {
    position: static;
  }

  &--static:not(&--inline) {
    width: 36rem;
    max-width: 100%;
  }

  &__toggle {
    display: none;

    .wm-jsm & {
      display: none;
    }
  }

  &__content {
    margin-left: $wm-spacing-large;
    display: block;
    position: relative;

    &--inline {
      margin: 0;
      display: none;
    }
  }

  &__submit {
    height: 100%;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0.5rem;

    [dir="rtl"] & {
      right: auto;
      left: 0.5rem;
    }
  }
}

.wm-search-results {
  &__link {
    padding: $wm-spacing-xxsmall $wm-spacing-small;
  }
}
