/**
  * .wm-search-result und wm-search-results 1.0.0
  * Suchergebnisse
  * Im Handbuch: /pattern-library/patterns/suchergebnis/
*/

.wm-search-results {
  @include wm-block($wm-spacing-large);

  & & {
    margin: $wm-spacing-large 0 0  $wm-spacing-large;
  }
}
