/*
 Import the additional styles from wm-design prototype for medium displays.
 This is (more or less) a copy of the file $project-dir/src/wm-design/assets/scss/main-medium.scss
*/

@import "../wm-design/assets/scss/abstracts/functions.scss";
@import "../wm-design/assets/scss/abstracts/colors.scss";
@import "../wm-design/assets/scss/abstracts/vars";
@import "../wm-design/assets/scss/abstracts/mixins.scss";
@import "../wm-design/assets/scss/vendor/mq";

/*
 In our Liferay build we cannot use the glob, as we would have to modify the 
 build for this and override a Liferay theme task or do some copy and replace 
 magic in a before hook.

 So we replaced this line with the explicit paths of the files.
*/
//@import "../wm-design/**/media/*medium.scss";
@import "../wm-design/_includes/components/stage/media/_stage_medium.scss";
@import "../wm-design/_includes/components/section/media/_section_medium.scss";
@import "../wm-design/_includes/components/feeds/media/_feed_medium.scss";
@import "../wm-design/_includes/components/carousel/media/_carousel_medium.scss";
@import "../wm-design/_includes/components/site/footer/media/_site-footer_medium.scss";
@import "../wm-design/_includes/components/site/content/media/_site-content_medium.scss";
@import "../wm-design/_includes/components/site/search/media/_site-search_medium.scss";
@import "../wm-design/_includes/components/block/media/_block_medium.scss";
@import "../wm-design/_includes/components/breaking-news/media/_breaking-news_medium.scss";
@import "../wm-design/_includes/components/hervorhebung/media/_hervorhebung_medium.scss";
@import "../wm-design/_includes/components/modal/media/_modal_medium.scss";
@import "../wm-design/_includes/components/accordion/media/_accordion_medium.scss";
@import "../wm-design/_includes/components/toggle/media/_toggle_medium.scss";
@import "../wm-design/_includes/components/form/media/_forms_medium.scss";
@import "../wm-design/_includes/components/card/media/_card_medium.scss";
@import "../wm-design/_includes/components/notification/media/_notification_medium.scss";
@import "../wm-design/_includes/components/progress/media/_progress_medium.scss";
@import "../wm-design/_includes/components/search/media/_search_medium.scss";
@import "../wm-design/_includes/components/quicklinks/media/_quicklinks_medium.scss";
@import "../wm-design/_includes/components/nav/inpage/media/_nav-inpage_medium.scss";
@import "../wm-design/_includes/components/nav/main/media/_nav-main__medium.scss";
@import "../wm-design/_includes/components/nav/tag/media/_nav-tag_medium.scss";
@import "../wm-design/_includes/components/tabs/media/_tabs_medium.scss";
@import "../wm-design/_includes/components/hervorhebung/media/_text-background_medium.scss";
