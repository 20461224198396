/**
  * colors 1.0.0
  * Zentrale Verwaltung der Farben
*/

// Werte mit "*" müssen übergeben werden
// Übergebene Werte: ("Name laut CD Manual*", RGB*, "CMYK", "PMS", "Beschreibung falls vorhanden")
$wm-brand-colors: (
  wien: ("Wien Rot", rgb(255, 0, 0), "0 100 100 0", "485C", "<p>Rot ist die Farbe der Stadt Wien. Sie ist historisch mit der Stadt verbunden und findet ihren Hauptausdruck im Wappen der Stadt. Auf wien.gv.at wird Rot nicht vollflächig eingesetzt.</p>"),
  fastschwarz: ("Fast Schwarz",rgb(41, 41, 41), "0 0 0 95", "447C"),
  weiss: ("Weiss",rgb(255, 255, 255), "0 0 0 0"),
  morgenrot: ("Morgenrot",rgb(255, 90, 100), "0 70 55 0", "2346C" ),
  morgenrot-light: ("Morgenrot Leicht", rgb(255, 206, 209), "0 20 15 0", "30% 2346C"),
  flieder: ("Flieder", rgb(170, 170, 250), "45 35 0 0", "3558C"),
  flieder-light: ("Flieder Leicht", rgb(230, 229, 254), "15 10 0 0", "30% 3558C"),
  abendstimmung: ("Abendstimmung",rgb(73, 39, 75), "70 95 30 10", "520C"),
  abendstimmung-light: ("Abendstimmung Leicht", rgb(212, 200, 212), "10 20 0 0", "30% 520C"),
  frischgruen: ("Frischgrün", rgb(130, 210, 130), "50 0 50 0", "2255C"),
  frischgruen-light: ("Frischgrün Leicht",rgb(205, 237, 205), "15 0 25 0", "30% 2255C"),
  goldgelb: ("Goldgelb", rgb(230, 200, 40), "5 5 80 5", "458C"),
  goldgelb-light: ("Goldgelb Leicht", rgb(248, 239, 189), "2 2 25 0", "30% 458C"),
  nebelgrau: ("Nebelgrau", rgb(214, 209, 202), "6 7 10 11", "Warm Grey 1C"),
  nebelgrau-light: ("Nebelgrau Leicht", rgb(243, 241, 239), "4 4 5 0", "30% Warm Grey 1C"),
  wasserblau: ("Gewässer", rgb(115, 206, 229), "50 0 8 0"),
  wasserblau-light: ("Gewässer Leicht",rgb(210, 240, 255))
);

$wm-ui-colors: (
  interaktiv: ("Interaktiv",rgb(75, 75, 130), "", "", "<p>Wird nur für Buttons und unbesuchte Links verwendet.</p>"),
  fehler: ("Fehler",rgb(145, 0, 0)),
  erfolg: ("Erfolg", rgb(0, 87, 56)),
  link: ("Link", rgb(31, 75, 175)),
  link-besucht: ("Link besucht", rgb(169, 30, 175))
);

$wm-map-colors: (
  verkehrsflaechen: ("Verkehrsflächen", rgb(157, 157, 157), "0 0 0 45"),
  wasserblau: ("Gewässer", rgb(115, 206, 229), "50 0 8 0")
);

$wm-infographic-colors: (
  morgenrot-1: ("Morgenrot Abstufung 1", rgb(255, 225, 225), "0 10 7 0"),
  morgenrot-light: ("Morgenrot Leicht",rgb(255, 206, 209), "0 20 15 0", "30% 2346C"),
  morgenrot-2: ("Morgenrot Abstufung 2", rgb(255, 160, 160),"0 50 30 0"),
  morgenrot-3: ("Morgenrot Abstufung 3",rgb(250, 110, 110),"0 70 50 0"),
  morgenrot-4: ("Morgenrot Abstufung 4",rgb(205, 0, 0), "0 90 80 0"),
  morgenrot-5: ("Morgenrot Abstufung 5",rgb(145, 0, 0), "0 100 100 25"),
  flieder-light: ("Flieder Leicht", rgb(230, 229, 254), "15 10 0 0", "30% 3558C"),
  flieder-1: ("Flieder Abstufung 1",rgb(184, 192, 250), "27 18 0 0"),
  flieder-2: ("Flieder Abstufung 2",rgb(153, 160, 222), "39 28 0 0"),
  flieder-3: ("Flieder Abstufung 3",rgb(120, 120, 175), "53 41 2 0"),
  flieder-4: ("Flieder Abstufung 4",rgb(75, 75, 130), "70 62 9 0"),
  flieder-5: ("Flieder Abstufung 5",rgb(70, 60, 110), "80 80 10 10"),
  abendstimmung-1: ("Abendstimmung Abstufung 1", rgb(240, 235, 240), "4 8 0 0"),
  abendstimmung-light: ("Abendstimmung Leicht", rgb(212, 200, 212), "10 20 0 0", "30% 520C"),
  abendstimmung-2: ("Abendstimmung Abstufung 2", rgb(165, 130, 160), "24 37 4 0"),
  abendstimmung-3: ("Abendstimmung Abstufung 3", rgb(123, 84, 129), "38 57 5 0"),
  abendstimmung-4: ("Abendstimmung Abstufung 4", rgb(88, 49, 88), "55 75 15 5"),
  abendstimmung: ("Abendstimmung",rgb(73, 39, 75), "70 95 30 10", "520C"),
  frischgruen-light: ("Frischgrün Leicht",rgb(205, 237, 205), "15 0 25 0", "30% 2255C"),
  frischgruen-1: ("Frischgrün Abstufung 1", rgb(167, 222, 184), "29 0 35 0"),
  frischgruen-2: ("Frischgrün Abstufung 2", rgb(107, 170, 118), "44 4 46 0"),
  frischgruen-3: ("Frischgrün Abstufung 3", rgb(86, 138, 94), "58 6 56 3"),
  frischgruen-4: ("Frischgrün Abstufung 4", rgb(0, 116, 72), "77 20 70 4"),
  frischgruen-5: ("Frischgrün Abstufung 5", rgb(0, 87, 56), "82 31 73 18"),
  goldgelb-light: ("Goldgelb Leicht", rgb(248, 239, 189), "2 2 25 0", "30% 458C"),
  goldgelb-1: ("Goldgelb Abstufung 1", rgb(255, 233, 88), "4 4 55 0"),
  goldgelb-2: ("Goldgelb Abstufung 2", rgb(248, 220, 12), "5 6 84 6"),
  goldgelb-3: ("Goldgelb Abstufung 3", rgb(203, 176, 0), "10 13 100 14"),
  goldgelb-4: ("Goldgelb Abstufung 4", rgb(165, 138, 0), "19 24 100 23"),
  goldgelb-5: ("Goldgelb Abstufung 5", rgb(133, 112, 0), "25 31 100 37"),
  nebelgrau-1: ("Nebelgrau Abstufung 1", rgb(238, 234, 228), "3 3 5 5"),
  nebelgrau: ("Nebelgrau", rgb(214, 209, 202), "6 7 10 11", "Warm Grey 1C"),
  nebelgrau-2: ("Nebelgrau Abstufung 2", rgb(166, 163, 157), "4 5 10 27"),
  nebelgrau-3: ("Nebelgrau Abstufung 3", rgb(125, 121, 119), "2 4 7 43"),
  nebelgrau-4: ("Nebelgrau Abstufung 4", rgb(96, 94, 94), "1 2 7 65"),
  nebelgrau-5: ("Nebelgrau Abstufung 5", rgb(76, 74, 74), "0 0 0 80")
);
