/**
  * .wm-hervorhebung 1.0.0
  * Bereich mit buntem Rahmen oben und unten
  * Im Handbuch: /pattern-library/patterns/hervorhebungen/
*/

.wm-hervorhebung {
  padding-top: $wm-spacing-large - $wm-spacing-xxsmall;
  padding-bottom: $wm-spacing-large - $wm-spacing-xxsmall;
}
