/**
  * .wm-modal 1.0.0
  * Lightbox
  * Im Handbuch: /pattern-library/patterns/lightbox/
*/

/*.wm-modal {
  &__item {
    &--basic {
      padding: $wm-spacing-large;
    }
  }
}*/
.wm-modal-content {
  &__control {
    width: 6.4rem;
    height: 6.4rem;
  }
}
