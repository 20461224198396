/**
  * .wm-feed 1.0.0
  * Events und andere Feeds, mit oder ohne Bild
  * Im Handbuch: /pattern-library/patterns/feeds/
*/

.wm-feed {
  .wm-image {
    display: block;
  }
}
