/**
  * .wm-notification 1.0.0
  * Tourismuszeile
  * Im Handbuch: /pattern-library/patterns/tourismuszeile/
*/

.wm-notification {
  p:not(:last-child) {
    margin-bottom: 1rem;
  }
}
