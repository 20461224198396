/**
  * .wm-nav-inpage 1.0.0
  * In Page Navigation
  * Im Handbuch: /pattern-library/patterns/in-page-navigation/
*/

.wm-nav-inpage {
  &__items {
    &--toggle {
      max-width: $wm-site-max-width-large;
      padding: $wm-spacing-large $wm-spacing-xlarge;
    }
  }

  &__toggle {
    margin-right: $wm-spacing-small;
  }

  &__link {
    padding: 1.8rem $wm-site-padding 1.4rem;
    font-size: $wm-f-size-medium;
  }
}
