/**
  * .wm-site-wrapper und .wm-site-content 1.0.0
  * Seiten Wrapper und Hauptinhalt der Seite
*/

.wm-site-content {
  &--no-stage {
    padding-top: $wm-spacing-xlarge - 1rem;
  }
}
