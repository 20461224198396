/**
  * mixins 1.0.0
  * Sass Mixins
*/

@import 'mixins/button-mixins';
@import 'mixins/progress-mixins';

@mixin state($state: "all") {
  @if $state == ":link" {
    &,
    &:link {
      @content;
    }
  }

  @else if $state == ":hover" {
    &:hover {
      @content;
    }
  }

  @else if $state == ":linkvisited" {
    &,
    &:link,
    &:visited {
      @content;
    }
  }

  @else if $state == ":visited" {
    &:visited {
      @content;
    }
  }

  @else if $state == ":active" {
    &:active {
      @content;
    }
  }

  @else if $state == ":focus" {
    .wm-jsm & {
      outline: none;
    }

    /* stylelint-disable */
    &.focus-visible {
      @content;
    }
    /* stylelint-enable */
  }

  @else {
    &:link,
    &:visited,
    &:hover,
    &:focus,
    &:active {
      @content;
    }
  }
}

@mixin wm-list-reset {
  list-style: none;
  padding: 0;
}

@mixin wrapper(
  $max-width: $wm-site-max-width-large,
  $padding: $wm-site-padding
) {
  max-width: $wm-site-max-width;
  margin: 0 auto;
  padding: 0 $padding;

  @include mq(m) {
    max-width: $max-width;
    padding-right: $wm-spacing-xlarge;
    padding-left: $wm-spacing-xlarge;
  }

  @include mq(l) {
    padding-right: $wm-spacing-large;
    padding-left: $wm-spacing-large;
  }

  @include mq(xl) {
    padding-right: $wm-spacing-xlarge;
    padding-left: $wm-spacing-xlarge;
  }

  @media print {
    max-width: 100%;
  }
}

@mixin wm-font-weight($weight, $legacy: $wm-f-regular, $variable: $wm-f-regular) {
  $wght: $wm-f-regular;
  $wghtVariable: $wght;

  @if $weight == "extrabold" {
    $wght: $wm-f-extrabold;
    $wghtVariable: $wm-f-extrabold-var;
  }

  @else if $weight == "bold" {
    $wght: $wm-f-bold;
    $wghtVariable: $wm-f-bold-var;
  }

  @else if $weight == "custom" {
    $wght: $legacy;
    $wghtVariable: $variable;
  }

  font-weight: $wght;

  @supports (font-variation-settings: normal) {
    font-variation-settings: "wght" $wghtVariable;
    font-weight: normal;
  }
}

@mixin inside($selector) {
  #{$selector} & {
    @content;
  }
}

@mixin vertical-space($margin: $wm-spacing-medium) {
  margin: 0 0 $margin;

  &:last-child {
    margin-bottom: 0;
  }
}

@mixin overflow-auto {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
}

@mixin sticky {
  // Fallback
  position: static;
  // Prefixes need for Safari https://caniuse.com/#search=sticky
  position: -webkit-sticky;
  position: sticky;
}

@mixin wm-empty-container {
  & {
    @content;
  }
}

@mixin hyphens($value: auto) {
  -webkit-hyphens: $value;
  -ms-hyphens: $value;
  hyphens: $value;
}

@mixin wm-visually-hidden {
  position: absolute;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  margin: -1px;
}

@mixin wm-visually-visible {
  clip-path: none;
  height: auto;
  width: auto;
  white-space: normal;
  clip: auto;
  margin: 0;
  overflow: visible;
  position: static;
}

// Search
@mixin searchActive {
  @include inside(".wm-jsm .wm-site-search--active") {
    @content;
  }
}

// Toggle:
@mixin toggle_content {
  .wm-no-js &,
  .wm-js:not(.wm-jsm) & {
    height: auto;
    visibility: visible;
    overflow: auto;
    opacity: 1;
  }

  .wm-js &,
  .wm-jsm & {
    visibility: hidden;
    height: 0;
    overflow: hidden;
    opacity: 0;

    &--is-visible {
      visibility: visible;
      opacity: 1 !important;
    }

    @media (prefers-reduced-motion: 'no-preference') {
      transition: height 0.3s ease-in-out, opacity 0.2s ease-in-out;
    }
  }

  .wm-js [data-mobile-only] &,
  .wm-jsm [data-mobile-only] & {
    @include mq(m) {
      display: block;
      overflow: visible;
      height: auto;
      visibility: visible;
      opacity: 1 !important;
    }
  }
}

// Mixins Thumb:
@mixin range-thumb {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: wm-brand-color("weiss");
  cursor: pointer;
  border: 8px solid wm-brand-color("fastschwarz");
  box-sizing: border-box;
  background-clip: content-box;
  -webkit-appearance: none;
}

@mixin range-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  animation: 0.2s;
  background: wm-brand-color("fastschwarz");
  border-radius: 0;
  border: 0px;
}

@mixin wm-block($size: $wm-spacing-medium) {
  & > * + * {
    margin-top: $size;
    margin-bottom: 0;

    @content;
  }

  & > .wm-h-vh:first-child + * {
    margin-top: 0;
  }
}
