/**
  * .wm-form 1.0.0
  * Formularstyles
  * Im Handbuch: /pattern-library/patterns/formulare/
*/

.wm-form {
  &__item {
    &--full {
      margin-right: $wm-grid-gap;
    }
  }

  &__grid-md {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    // zeige items in 2er reihen
    & > *:not(fieldset) {
      flex-basis: calc(50% - #{$wm-grid-gap} / 2);
    }

    // zeige items in 3er reihen
    &--3 > *:not(fieldset) {
      flex-basis: calc(33.33% - #{$wm-grid-gap} / 3);
    }
  }

  &__row-md {
    margin-top: -$wm-grid-gap;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;

    > * {
      margin-right: $wm-grid-gap;
      margin-bottom: $wm-grid-gap;
    }

    > *:last-child {
      margin-right: 0;
    }
  }
}

.wm-form__item + fieldset {
  margin-top: $wm-grid-gap * 2;
}
