/**
  * .wm-progress 1.0.0
  * Fortschrittsanzeige in Formularen
  * Im Handbuch: /pattern-library/patterns/formulare/#darstellung_fortschrittsanzeige
*/

.wm-progress {
  padding-top: 1em;

  &__text {
    .wm-progress__step:nth-child(3) &,
    .wm-progress__step:nth-child(4) & {
      @include wm-progress-right-center;
    }

    .wm-progress__step:nth-last-child(-n+3):first-child &,
    .wm-progress__step:nth-last-child(-n+4):first-child & {
      @include wm-progress-left-center;
    }

    .wm-progress__step:nth-last-child(-n+4):first-child &,
    .wm-progress__step:nth-last-child(-n+4):first-child ~ .wm-progress__step & {
      display: block;
    }
  }
}
